import { AvatarUpload, Block, DatePickerField, FlexBox, FormBase, FormLayoutModal, Header, IconCamera, InputField, SelectField, Spacer } from '@esg/ui';
import { Card } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { EmployeeInitCreate, JobTypeInitCreate } from '@esg/business-account';
import { dayjs } from '@esg/shared';
import { FieldValues, useForm, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { jobTypeStatusOption } from '../../@shared/dataSample';
import { translateCodes } from '@/locales';
import { EmployeeRules } from '@/pages/rules/EmployeeRules';

interface EmployeeFormProps{
    defaultData?: EmployeeInitCreate;
    onSubmit: (value: EmployeeInitCreate) => void;     
    onCancel: () => void;
    isUpdate?: boolean;
    watch: UseFormWatch<FieldValues>;
    setValue: UseFormSetValue<FieldValues>
}

export const EmployeeForm = (props: EmployeeFormProps) => {
    const { defaultData, onSubmit, isUpdate, onCancel, watch, setValue } = props;
    const [ currentLocale ] = useRecoilState(currentLocaleState);
    const [ jobTypes, setJobTypes ] = useState<JobTypeInitCreate[]>(watch('jobType') || []);
    const { t } = useTranslation();

    const handleCreateJobType = useCallback((form: ReturnType<typeof useForm>, value?: string)=> {
        if (value) {
            const lastService = jobTypes?.findLast(o=>o);
            const newId = lastService ? lastService?.tempId + 1 : 0;

            const newJobType: JobTypeInitCreate = {
                tempId: newId,
                name: value || '',
            };
            const updatedJobType = [...jobTypes, newJobType];
            setJobTypes(updatedJobType);
            setValue('jobType', updatedJobType);
            form.setValue('tempJobTypeId', newJobType.tempId);
        }
    },[jobTypes, setValue]);

    return (
        <FormBase onSubmit={onSubmit} defaultValues={{...defaultData, jobStatus: defaultData ? defaultData.jobStatus : 0}}>
            {(form) => (
                <FormLayoutModal
                    useSubmitButton={true}
                    okTextCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                    cancelTextCode={translateCodes.CANCEL}
                    onCancel={onCancel}
                    alwaysEnableSubmit={!isUpdate}
                >
                    <Block>
                        <Header level={4} translateCode={translateCodes.GENERAL_INFO} />
                        <Spacer bottom={12} />
                        <Card>
                            <AvatarUpload 
                                iconHover={<IconCamera width={20} height={20} />} 
                                translateCode={translateCodes.UPLOAD_PHOTO}
                                name='avatar' 
                                disabled 
                            />
                            <Spacer bottom={32} />
                            <FlexBox direction='column' gap={16}>
                                <FlexBox gap={16}>
                                    <InputField
                                        label={translateCodes.FIRST_NAME}
                                        name='firstName'
                                        placeholder={translateCodes.FIRST_NAME_PLACEHOLDER}
                                        rules={EmployeeRules.firstName(t)}
                                    />
                                    <InputField
                                        label={translateCodes.LAST_NAME}
                                        name='lastName'
                                        placeholder={translateCodes.FIRST_NAME_PLACEHOLDER}
                                        rules={EmployeeRules.lastName(t)}
                                    />
                                </FlexBox>
                                <FlexBox gap={16}>
                                    <InputField
                                        name='email'
                                        placeholder={translateCodes.EMAIL_PLACEHOLDER}
                                        label={translateCodes.EMAIL}
                                        rules={EmployeeRules.email(t)}
                                    />
                                    <InputField
                                        label={translateCodes.PHONE_NUMBER}
                                        name='phone'
                                        placeholder={translateCodes.PHONE_PLACEHOLDER}
                                        rules={EmployeeRules.phone}
                                    />
                                </FlexBox>
                            </FlexBox>
                        </Card>
                    </Block>
                    <Spacer bottom={24} />
                    <Block>
                        <Header level={4} translateCode={translateCodes.JOB_INFO} />
                        <Spacer bottom={12} />
                        <Card>
                            <FlexBox direction='column' gap={16}>
                                <FlexBox gap={16}>
                                    <FlexBox>
                                        <SelectField
                                            filterOption={false}
                                            showSearch
                                            onSearch={()=>{}}
                                            allowClear
                                            label={translateCodes.JOB_TYPE}
                                            name='tempJobTypeId'
                                            typeCreate='input'
                                            placeholder={translateCodes.SELECT_JOB_TYPE}
                                            createTextCode={translateCodes.CREATE_JOB_TYPE}
                                            options={jobTypes?.map(o=>({
                                                ...o,
                                                label: o.name,
                                                value: o.tempId,
                                            }))}
                                            onCreate={(value)=> handleCreateJobType(form, value)}
                                        />
                                    </FlexBox>
                                    <FlexBox>
                                        <DatePickerField
                                            label={translateCodes.START_DATE}
                                            name='jobStartDate'
                                            placeholder={translateCodes.START_DATE_PLACEHOLDER}
                                            format={ currentLocale?.format }
                                            defaultValue={dayjs()}
                                        />
                                    </FlexBox>
                                </FlexBox>
                                <FlexBox gap={16}>
                                    <SelectField
                                        label={translateCodes.STATUS}
                                        name='jobStatus'
                                        placeholder={translateCodes.STATUS_PLACEHOLDER}
                                        options={jobTypeStatusOption}
                                    />
                                </FlexBox>
                            </FlexBox>
                        </Card>
                    </Block>
                </FormLayoutModal>
            )}
        </FormBase>
    );
};
