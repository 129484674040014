import React from 'react';

import { IconProps } from '../../Types';

export const IconCamera = (props: IconProps) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M1.66699 6.98232C1.66699 6.69039 1.66699 6.54442 1.67917 6.42148C1.79666 5.23569 2.73472 4.29764 3.9205 4.18015C4.04345 4.16797 4.19729 4.16797 4.50498 4.16797C4.62354 4.16797 4.68282 4.16797 4.73315 4.16492C5.37583 4.126 5.93862 3.72037 6.17878 3.12297C6.19759 3.07618 6.21517 3.02345 6.25033 2.91797C6.28548 2.81249 6.30306 2.75975 6.32187 2.71297C6.56203 2.11557 7.12482 1.70994 7.7675 1.67102C7.81784 1.66797 7.87343 1.66797 7.98461 1.66797H12.016C12.1272 1.66797 12.1828 1.66797 12.2331 1.67102C12.8758 1.70994 13.4386 2.11557 13.6788 2.71297C13.6976 2.75975 13.7152 2.81249 13.7503 2.91797C13.7855 3.02345 13.8031 3.07618 13.8219 3.12297C14.062 3.72037 14.6248 4.126 15.2675 4.16492C15.3178 4.16797 15.3771 4.16797 15.4957 4.16797C15.8034 4.16797 15.9572 4.16797 16.0802 4.18015C17.2659 4.29764 18.204 5.23569 18.3215 6.42148C18.3337 6.54442 18.3337 6.69039 18.3337 6.98232V13.5013C18.3337 14.9014 18.3337 15.6015 18.0612 16.1363C17.8215 16.6067 17.439 16.9891 16.9686 17.2288C16.4339 17.5013 15.7338 17.5013 14.3337 17.5013H5.66699C4.26686 17.5013 3.5668 17.5013 3.03202 17.2288C2.56161 16.9891 2.17916 16.6067 1.93948 16.1363C1.66699 15.6015 1.66699 14.9014 1.66699 13.5013V6.98232Z' stroke='#344054' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M10.0003 13.7513C11.8413 13.7513 13.3337 12.2589 13.3337 10.418C13.3337 8.57702 11.8413 7.08464 10.0003 7.08464C8.15938 7.08464 6.66699 8.57702 6.66699 10.418C6.66699 12.2589 8.15938 13.7513 10.0003 13.7513Z' stroke='#344054' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};

IconCamera.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#4A5578',
};
