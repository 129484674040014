import { ServiceInitCreate } from '@esg/business-account';
import { AppButton, Block, FlexBox, globalModalState, Spacer, Text, useLocalesFormat } from '@esg/ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { ServiceDeleteConfirm } from './ServiceDeleteConfirm';
import { translateCodes } from '@/locales';

interface ServiceItemProps{
    dataService?: ServiceInitCreate[];
    showButton?: boolean;
    onEdit?: (categoryIndex?: number, serviceIndex?: number) => void;
    onDelete?: (categoryIndex?: number, serviceIndex?: number) => void;
    watch: UseFormWatch<FieldValues>
}
export const ServiceItem = (props: ServiceItemProps) => {
    const { dataService, showButton, onDelete, onEdit, watch } = props;
    const { t } = useTranslation();
    const { formatCurrencySymbol } = useLocalesFormat();
    const [ globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const onDeleteService = useCallback((serviceIndex?: number) => {
        const currentService = dataService?.find(o=>o.id == serviceIndex);

        setGlobalModal({
            ...globalModal,
            isOpen: true,
            showModalDelete: true,
            footer: undefined,
            content: <ServiceDeleteConfirm item={currentService!}/>,
            onOk: () => {
                onDelete?.(currentService?.id);
                resetGlobalModal();
            },
        });
    }, [dataService, globalModal, onDelete, resetGlobalModal, setGlobalModal]);

    if (dataService && dataService?.length > 0 && dataService != undefined) {
        return (
            <Block className='service-item-box'>
                <FlexBox direction='column' gap={12} >
                    {dataService.map((o, index) => (
                        <Row key={index} className='service-item'>
                            <Col span={10}>
                                <FlexBox alignItems='center' gap={14}>
                                    {showButton && <Tooltip placement='topLeft' title={t(translateCodes.DELETE_SERVICE_TITLE)}>
                                        <AppButton
                                            icon={<CloseOutlined />}
                                            onClick={() => onDeleteService?.(o.id)}
                                        />
                                    </Tooltip>}
                                    {o.name}
                                </FlexBox>
                            </Col>
                            <Col span={8}>
                                {o.duration}min
                            </Col>
                            <Col span={5}>
                                {`${formatCurrencySymbol(watch('country'), watch('currency'))} ${o.price}`}
                            </Col>
                            <Col>
                                {showButton &&  <Tooltip placement='topLeft' title={t(translateCodes.UPDATE_SERVICE_TITLE)}>
                                    <AppButton
                                        icon={<RightOutlined />}
                                        onClick={() => onEdit?.(o.tempCategoryId, o.id)}
                                    />
                                </Tooltip>}
                            </Col>
                        </Row>
                    ))}
                </FlexBox>
            </Block>
        );
    }
    return (
        <Block 
            backgroundColor='#F9FAFB'
            borderRadius={8}
        >
            <Spacer type='padding' top_bottom={16} left_right={16}>
                <FlexBox alignItems='center' direction='column' gap={16}>
                    <img src='/assets/auth/Empty_item.svg' title='empty-service' width={120}/>
                    <Text fontSize={14} color='#667085'>{t(translateCodes.NO_SERVICE)}</Text>
                </FlexBox>
            </Spacer>
        </Block>
    );
};
